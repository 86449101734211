export default defineNuxtRouteMiddleware(to => {
  if (to.path === '/maintenance') {
    return
  }

  // In case we want to use var env
  // const config = useRuntimeConfig()
  // const isMaintenanceMode = config.public.MAINTENANCE_MODE === '1'
  const isMaintenanceMode = true

  if (isMaintenanceMode) {
    return navigateTo('/maintenance')
  }
})
