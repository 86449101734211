export function useReference(referenceData = {}, publicStockData = []) {
  const defaultImage = 'https://d3brsr9pdomwt0.cloudfront.net/illustrations/empty-product-img.png'
  const reference = {
    ...referenceData,
    price: referenceData.recommended_price,
    publicStocks: publicStockData,
    availableStocks: referenceData.public_stocks_count,
    refCo: referenceData.ref_co,
    hasDelivery: referenceData.has_delivery,
    manualLink: referenceData.manual_link,
    unitWeight: referenceData.unit_weight,
    weightUnit: referenceData.weight_unit,
    publicStocksQuantities: referenceData.public_stocks_quantities,
    ecoTax: referenceData.eco_tax,
    relatedReferences: referenceData.related_references,
    publicStocksCountries: referenceData.public_stock_countries,
    bestStock: referenceData.best_stock,
  }

  const path = `${reference.uid}-${encodeURI(reference.name)}`

  const findNextCategoryName = currentCategory => {
    if (currentCategory.parent !== null && currentCategory.parent !== undefined) {
      if (currentCategory.parent.name !== null && currentCategory.parent.name !== undefined) {
        return currentCategory.parent.name
      }

      return null
    }
    return null
  }

  const ean = () => {
    if (reference.ean !== null && reference.ean !== undefined) {
      if (!reference.ean.includes('stockpro_ean')) {
        return reference.ean
      }
    }
  }

  const thumbnailUrl = () => {
    if (reference.images !== null && reference.images !== undefined && reference.images?.length > 0) {
      if (reference.images?.[0]?.url_thumbnail !== undefined && reference.images?.[0]?.url_thumbnail !== null) {
        return reference.images?.[0]?.url_thumbnail
      } else {
        return reference.images?.[0]?.url
      }
    } else {
      return defaultImage
    }
  }

  const trackingId = () => {
    if (reference.ean !== null && reference.ean !== undefined) {
      return reference.ean
    }
    if (reference.refCo !== null && reference.refCo !== undefined) {
      return reference.refCo
    }
    return reference.uid
  }

  const hasCategory = () => {
    if (reference.category !== null && reference.category !== undefined) return true
    return false
  }

  const categoryName = () => {
    if (hasCategory() && reference.category?.name !== undefined && reference.category?.name !== null)
      return reference.category?.name
    return ''
  }

  const categoriesName = () => {
    if (hasCategory() && reference.category?.name !== undefined && reference.category?.name !== null) {
      const categoriesName = [categoryName()]
      let currentCategory = reference.category
      let hasNext = true
      while (hasNext) {
        if (findNextCategoryName(currentCategory) !== null) {
          categoriesName.push(findNextCategoryName(currentCategory))
          currentCategory = currentCategory.parent
        } else hasNext = false
      }
      return categoriesName.reverse()
    }

    return []
  }

  const hasBrand = () => {
    return reference.brand !== null && reference.brand !== undefined && Object.keys(reference.brand)?.length > 0
  }

  const hasBrandLogo = () => {
    return (
      hasBrand() &&
      reference.brand?.logo_url !== undefined &&
      reference.brand?.logo_url !== null &&
      reference.brand?.logo_url.length > 0
    )
  }

  const brandName = () => {
    if (hasBrand()) return reference.brand?.name
    return null
  }

  const brandUid = () => {
    if (hasBrand()) return reference.brand?.uid
    return null
  }

  const numberOfImages = () => {
    if (reference.images !== null && reference.images !== undefined) return reference.images?.length
    return 0
  }

  const mainImageUrl = () => {
    if (numberOfImages() > 0) return reference.images?.[0]?.url_medium

    return defaultImage
  }

  const imagesUrl = () => {
    if (numberOfImages() > 0) {
      return reference.images?.map(image => image.url_medium)
    }
    return []
  }

  const hasStock = () => {
    return (
      (reference.bestStock !== null &&
        reference.bestStock !== undefined &&
        Object.keys(reference.bestStock)?.length !== 0) ||
      (reference.publicStocks !== null && reference.publicStocks !== undefined && reference.publicStocks.length > 0)
    )
  }

  return {
    ...reference,
    path: path,
    thumbnailUrl: thumbnailUrl(),
    ean: ean(),
    trackingId: trackingId(),
    hasCategory: hasCategory(),
    categoryName: categoryName(),
    categoriesName: categoriesName(),
    hasBrand: hasBrand(),
    hasBrandLogo: hasBrandLogo(),
    brandName: brandName(),
    brandUid: brandUid(),
    numberOfImages: numberOfImages(),
    mainImageUrl: mainImageUrl(),
    imagesUrl: imagesUrl(),
    hasStock: hasStock(),
    findNextCategoryName: findNextCategoryName,
  }
}
